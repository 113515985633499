import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { BrowserView, isMobile, isIOS } from 'react-device-detect';
import QRCode from 'react-qr-code';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { HEADER_HEIGHT, BOX_SHADOW, TEXT_COLOR } from 'shared_components/src/common/styles';
import Footer from 'shared_components/src/components/layout/Footer';
import Header from 'shared_components/src/components/layout/public/Header';
import { getCookie } from 'shared_components/src/service/common.service';
import { CONFIGURATION_COOKIES } from 'shared_components/src/common/constants';
import styled from 'styled-components';
import { Grid, Card, CardContent, Theme } from '@mui/material';
import { useLocation } from 'react-router-dom';
import KycApiService from '../../service/kycApi.service';
import { setLoading, clearLoading } from '../../store/common/actions';
import { _getLoadingState } from '../../store/selectors';

/**
 * Props
 */

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) => ({
	// informationSection: {
	// 	backgroundColor: '#fafafa',
	// },

	title: {
		color: '#9c0de2',
		fontSize: '24px',
		fontWeight: 'bold',
		textAlign: 'left',
	},
	textPara: {
		textAlign: 'left',
	},
	textQR: {
		fontSize: '16px',
		'@media screen and (max-width: 960px)': {
			textAlign: 'center',
			fontSize: '12px',
		},
		'@media screen and (max-width: 600px)': {
			textAlign: 'center',
			fontSize: '12px',
		},
	},
	infoList: {
		margin: '45px 0 45px 0',
		padding: '0',
	},
	orgTitle: {
		textTransform: 'uppercase',
	},
	requestSection: {
		margin: '5rem auto',
		borderLeft: 'solid 11px #9c0de2',
		borderRadius: '16px',
		width: '60%',
		boxShadow: '0 3px 6px 0 rgba(0,0,0,0.16)',
	},
	appBarSpacer: {
		height: HEADER_HEIGHT[0],
		...theme.mixins.toolbar,

		'@media screen and (max-width: 900px)': {
			marginLeft: 'auto',
			height: HEADER_HEIGHT[1],
		},

		'@media screen and (max-width: 600px)': {
			marginLeft: 'auto',
			height: HEADER_HEIGHT[2],
		},
	},
	logos: {
		display: 'flex',
		justifyContent: 'flex-end',
		color: TEXT_COLOR.graydark,
		marginLeft: 'auto',
		marginRight: 'auto',

		'& .logo': {
			justifyContent: 'center',
			alignItems: 'center',

			'& .image': {
				width: '100%',
				maxWidth: 98,
				objectFit: 'content',
			},

			'& label': {
				marginTop: 2,
				color: theme.palette.grey[900],
				fontSize: 10,
				lineHeight: 1.4,
				textAlign: 'center',
			},

			'& .content': {
				textAlign: 'center',
			},
		},
	},
	qrCode: {
		width: 190,

		'@media screen and (max-width: 960px)': {
			display: 'flex',
			margin: 'auto !important',
			width: 160,
		},

		'@media screen and (max-width: 600px)': {
			display: 'flex',
			margin: 'auto !important',
			width: 160,
		},
	},
}));

/**
 * Styled Components
 */
const LayoutContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	overflow: auto;

	@media screen and (max-width: 600px) {
		min-height: 100vh;
	}
`;

const LayoutWrapper = styled.div`
	display: flex;
	flex-wrap: nowrap;
	width: 100%;
	min-height: 100vh;
	min-width: 200px;
`;

const MainContainer = styled.div`
	height: 100%;
	width: 100%;
	overflow: auto;
`;

const MainContainerSpacer = styled.div``;

const MainWrapper = styled.div`
	overflow-y: auto;

	max-height: calc(100vh - ${HEADER_HEIGHT[0]});
	height: calc(100% - ${HEADER_HEIGHT[0]});
	padding: 0;

	@media screen and (max-width: 900px) {
		height: calc(100% - ${HEADER_HEIGHT[1]});
		max-height: calc(100vh - ${HEADER_HEIGHT[1]});
	}

	@media screen and (max-width: 600px) {
		height: calc(100% - ${HEADER_HEIGHT[2]});
		max-height: calc(100vh - ${HEADER_HEIGHT[2]});
		box-shadow: none;
	}
`;

const MainContent = styled.div`
	padding: 20px;
	box-shadow: inset ${BOX_SHADOW.secondary};
	min-height: calc(100% - 60px);

	@media screen and (max-width: 1440px) {
		min-height: calc(100% - 60px);
	}

	@media screen and (max-width: 1280px) {
		min-height: calc(100% - 50px);
	}

	@media screen and (max-width: 600px) {
		padding: 0;
		min-height: calc(100% - 50px);
	}
`;

/**
 * Main Component
 */
const RequestLaunch = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const v = new URLSearchParams(useLocation().search);
	const vId = v.get('verification');
	const tName = v.get('tenantname');
	const tenantAlias = v.get('tenant');
	const redirecturl = v.get('redirecturl');
	const [deeplinkUrl, setDeepLink] = useState('');
	const tenant = getCookie(CONFIGURATION_COOKIES.tenant);
	const _tenantInfo = getCookie(tenant);
	const tenantInfo = _tenantInfo ? JSON.parse(_tenantInfo) : null;
	const loadings = _getLoadingState();
	const flags = useFlags();

	/**
	 * Deeplink build
	 */
	useEffect(() => {
		getVerificationData();
	}, [vId, tName]);

	const getCustomeStage = () => {
		const lanes = ['dev', 'test', 'sb'];
		const locationArr = window.location.hostname.split('.');
		const currentLane = locationArr[2];
		return lanes.includes(currentLane) ? currentLane : '';
	};

	const getVerificationData = () => {
		if (vId && tenantAlias) {
			dispatch(setLoading());
			KycApiService.getDeepLink(vId, tenantAlias)
				.then((res: any) => {
					if (res?.deepLinkUrl) {
						setDeepLink(res?.deepLinkUrl);
					}
					dispatch(clearLoading());
				})
				.catch((err: any) => {
					dispatch(clearLoading());
				});
		}
	};

	const encodedURL = () => {
		const customeState = getCustomeStage();
		const region = tenantInfo?.regionAlias || 'au';
		const appPath = isIOS && flags['KYCAPP-Beta'] ? 'kycappbeta' : 'kycapp';

		if (customeState === '') {
			const url = `https://${tenantAlias}.${region}.truuth.id/${appPath}/index.html?tenantname=${tName}&verification=${vId}&tenant=${tenantAlias}${
				redirecturl ? `&redirecturl=${redirecturl}` : ''
			}`;
			return encodeURI(url);
		} else {
			const url = `https://${tenantAlias}.${region}.${customeState}.truuth.id/${appPath}/index.html?tenantname=${tName}&verification=${vId}&tenant=${tenantAlias}${
				redirecturl ? `&redirecturl=${redirecturl}` : ''
			}`;
			return encodeURI(url);
		}
	};

	if (isMobile) {
		window.location.href = encodedURL();
	}

	if (loadings > 0) {
		return null;
	}

	return (
		<div>
			<BrowserView>
				<LayoutContainer>
					<Header isMobile={true} />
					<LayoutWrapper>
						<MainContainer>
							<MainContainerSpacer className={classes.appBarSpacer} />
							<MainWrapper>
								<MainContent>
									<Card className={classes.requestSection}>
										<CardContent>
											<Grid container spacing={10}>
												<Grid
													item
													// className={classes.informationSection}
													xs={12}
													md={6}
													lg={8}
												>
													{deeplinkUrl ? (
														<>
															<p className={classes.title}>
																Identity Verification Request
															</p>
															<p className={classes.textPara}>
																<span className={classes.orgTitle}>
																	{tName || ''}
																</span>{' '}
																has sent a request for you to verify your
																identity but it looks like you’re not on your
																phone.
															</p>
															<div className={classes.infoList}>
																<ol
																	style={{
																		textAlign: 'left',
																		paddingLeft: '15px',
																	}}
																>
																	<li>Open your Phone Camera</li>
																	<li>Scan the QR Code</li>
																</ol>
															</div>
														</>
													) : (
														<>
															<p className={classes.title}>
																Verification Link Expired
															</p>
															<p className={classes.textPara}>
																Your verification link previously provided is
																no longer valid. <br />
																Please contact{' '}
																<span className={classes.orgTitle}>
																	{tName || ''}
																</span>{' '}
																to request a new verification invitation link.
															</p>
														</>
													)}
													<div>
														<p className={classes.textPara}>
															For more information please visit{' '}
															<a
																href='https://www.truuth.id/faq'
																target='_blank'
																rel='noreferrer'
															>
																https://www.truuth.id/faq
															</a>
														</p>
													</div>
												</Grid>
												{deeplinkUrl && (
													<Grid item xs={12} md={6} lg={4}>
														<QRCode
															size={180}
															bgColor={'#ffffff'}
															fgColor={'#000000'}
															level={'L'}
															value={deeplinkUrl}
															// renderAs={'svg'}
															style={{
																marginTop: '25px',
																marginRight: '20px',
															}}
															className={classes.qrCode}
														/>
														<p className={classes.textQR}>
															Scan QR Code to Start process
														</p>
													</Grid>
												)}
											</Grid>
										</CardContent>
									</Card>
								</MainContent>
								<Footer />
							</MainWrapper>
						</MainContainer>
					</LayoutWrapper>
				</LayoutContainer>
			</BrowserView>
		</div>
	);
};

export default RequestLaunch;
